import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import siteImage from "../images/site-image-1200x630.png"
import twitterImage from "../images/site-image-800x418.png"

function SEO({ description, lang, meta, keywords, title, pathname }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription = description || data.site.siteMetadata.description;
        const titleResult = (title ? `${title} | ` : '') + data.site.siteMetadata.title

        return (
          <Helmet
            htmlAttributes={{
              lang
            }}
            title={titleResult}
            link={[
              {
                href: `${data.site.siteMetadata.url.slice(0, -1)}${pathname}`,
                rel: `canonical`
              },
              {
                href: data.site.siteMetadata.url,
                rel: `home`
              },
              {
                href: data.site.siteMetadata.url,
                rel: `alternate`,
                hreflang: `x-default`
              }
            ]}
            meta={[
              {
                name: `referrer`,
                content: `no-referrer-when-downgrade`
              },
              {
                name: `robots`,
                content: `all`
              },
              {
                property: `fb:profile_id`,
                content: `https://facebook.com/GortekLLC/`
              },
              {
                name: `description`,
                content: metaDescription
              },
              {
                property: `og:site_name`,
                content: data.site.siteMetadata.name
              },
              {
                property: `og:url`,
                content: `${data.site.siteMetadata.url.slice(0, -1)}${pathname}`
              },
              {
                property: `og:title`,
                content: titleResult
              },
              {
                property: `og:description`,
                content: metaDescription
              },
              {
                property: `og:type`,
                content: `website`
              },
              {
                property: `og:locale`,
                content: `en_US`
              },
              {
                property: `og:image`,
                content: `${data.site.siteMetadata.url.slice(0, -1)}${siteImage}`
              },
              {
                property: `og:image:width`,
                content: `1200`
              },
              {
                property: `og:image:height`,
                content: `630`
              },
              {
                name: `twitter:card`,
                content: `summary`
              },
              {
                name: `twitter:site`,
                content: `@GortekLLC`
              },
              {
                name: `twitter:creator`,
                content: `@GortekLLC`
              },
              {
                name: `twitter:title`,
                content: titleResult
              },
              {
                name: `twitter:description`,
                content: metaDescription
              },
              {
                name: `twitter:image`,
                content: `${data.site.siteMetadata.url.slice(0, -1)}${twitterImage}`
              },
              {
                name: `twitter:image:width`,
                content: `800`
              },
              {
                name: `twitter:image:height`,
                content: `418`
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `)
                    }
                  : []
              )
              .concat(meta)}
          />
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: []
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        name
        title
        description
        author
        url
      }
    }
  }
`;
