import React from "react";

function getCode() {
  return '(function() {if (navigator.userAgent.indexOf("FBIOS") !== -1 || navigator.userAgent.indexOf("Twitter for iPhone") !== -1) {document.getElementById("af-form-690461412").parentElement.removeAttribute("target");}})();';
}

function MailingList() {
  return (
    <>
      <form
        method="post"
        className="af-form-wrapper"
        acceptCharset="UTF-8"
        action="https://www.aweber.com/scripts/addlead.pl"
      >
        <div className="hidden">
          <input type="hidden" name="meta_web_form_id" value="690461412" />
          <input type="hidden" name="meta_split_id" value="" />
          <input type="hidden" name="listname" value="awlist5269491" />
          <input
            type="hidden"
            name="redirect"
            value=""
            id="redirect_5fcbb64aa30d30545c693d95864bb87f"
          />

          <input type="hidden" name="meta_adtracking" value="" />
          <input type="hidden" name="meta_message" value="1" />
          <input type="hidden" name="meta_required" value="name,email" />
          <input type="hidden" name="meta_forward_vars" value="1" />
          <input type="hidden" name="meta_tooltip" value="" />
        </div>
        <div id="af-form-690461412" className="af-form">
          <div id="af-body-690461412" className="af-body af-standards">
            <div className="af-element">
              <div className="bodyText">
                <h3 className="text-2xl mb-2">
                  Do you want to be the first to read our new articles?
                </h3>
                Join our mailing list for inspiring stories, strategy, and
                useful information. And the occasional special offer that can't
                be found anywhere else. 😉
              </div>
              <div className="af-clear"></div>
            </div>
            <div className="af-element w-full">
              <div className="af-textWrap text-black my-5 w-full">
                <input
                  id="awf_field-100906911"
                  className="p-3 w-full"
                  placeholder="First Name"
                  type="text"
                  name="name"
                  label="First Name"
                  aria-label="First Name"
                  tabIndex="0"
                />
              </div>
              <div className="af-clear"></div>
            </div>
            <div className="af-element">
              <div className="af-textWrap text-black my-5">
                <input
                  id="awf_field-100906912"
                  className="p-3 w-full"
                  placeholder="Best Email"
                  type="text"
                  name="email"
                  label="Email"
                  aria-label="Email"
                  tabIndex="0"
                />
              </div>
              <div className="af-clear"></div>
            </div>
            <div className="af-element w-full">
              <input
                className="rounded p-3 w-full bg-secondary text-black font-bold sm:text-xl text-lg cursor-pointer focus:bg-orange-600 hover:bg-orange-600 truncate"
                name="submit"
                type="submit"
                value="Get Knowledge, Be Inspired!"
                tabIndex="0"
              />
              <div className="af-clear"></div>
            </div>
          </div>
        </div>
      </form>
      <script dangerouslySetInnerHTML={{ __html: getCode() }} />
    </>
  );
}

export default MailingList;
