import React, { useState, useEffect, useContext } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { Location } from "@reach/router";

import useDimensions from "./useDimensions";
import HeaderContext from "../contexts/HeaderContext";

function Header({ siteTitle }) {
  const headerAttr = useContext(HeaderContext);

  const [isExpanded, toggleExpansion] = useState(false);
  const [trigger, setTrigger] = useState(false);

  const [ref, dimensions] = useDimensions(trigger);

  useEffect(() => {
    setTimeout(() => setTrigger(prevState => !prevState), 50);

    const handleResize = () => {
      setTrigger(prevState => !prevState);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const computedHeight = dimensions.top + dimensions.bottom;

  if (computedHeight < 136) {
    headerAttr.update({ height: computedHeight });
  }

  return (
    <>
      <nav ref={ref} className="bg-bg sticky top-0 z-50 md:my-8">
        <div className="flex flex-wrap items-center justify-between max-w-5xl mx-auto p-4 md:py-0">
          <Link to="/" className="flex items-center no-underline">
            <img
              alt="Gortek logo"
              className="h-12"
              src={require(`../images/logo.svg`)}
            />
          </Link>

          <button
            className="block md:hidden border border-white flex items-center px-3 py-2 rounded"
            onClick={() => toggleExpansion(!isExpanded)}
          >
            <svg
              className="fill-current h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>

          <div
            className={`${
              isExpanded ? `block` : `hidden`
            } md:block md:items-center w-full md:w-auto flex justify-center`}
          >
            <div
              className="text-base bg-bg mx-auto md:shadow-none shadow-xl md-py-0 py-3 md:relative md:w-auto absolute w-full z-50 md:overflow-y-visible overflow-y-auto md:top-0 top-62"
              style={{ maxHeight: `calc(100vh - 62px)` }}
            >
              <Link
                to="/"
                className="block md:inline-block my-2 md:my-0 md:mr-3 md:mx-0 mx-3 p-3 no-underline rounded hover:bg-gray-200"
                activeClassName="font-bold bg-gray-200"
              >
                Home
              </Link>

              <div className="group block md:inline-block">
                <Location>
                  {({ location }) => (
                    <div
                      className={`
                        md:inline-block md:my-0 md:mr-3 md:mx-0
                        relative block my-2 mx-3 p-3 no-underline rounded cursor-pointer
                        hover:bg-gray-200
                        ${
                          location.pathname.slice(0, 9) === "/services"
                            ? "font-bold bg-gray-200"
                            : null
                        }
                      `}
                    >
                      Services
                    </div>
                  )}
                </Location>
                <div className="md:hidden group-hover:block md:absolute max-w-sm md:rounded overflow-hidden md:shadow-lg p-4 md:bg-bg bg-gray-100">
                  <Link
                    to="/services/app-startups"
                    className="block p-3 hover:bg-gray-200 rounded md:ml-0 ml-4"
                  >
                    Apps For Startups
                  </Link>
                  <Link
                    to="/services/custom-software-development"
                    className="block p-3 hover:bg-gray-200 rounded md:ml-0 ml-4"
                  >
                    Custom Software Development
                  </Link>
                </div>
              </div>

              <Link
                to="/projects"
                className="block md:inline-block my-2 md:my-0 md:mr-3 md:mx-0 mx-3 p-3 no-underline rounded hover:bg-gray-200"
                activeClassName="font-bold bg-gray-200"
                partiallyActive={true}
              >
                Projects
              </Link>

              {/*
              <Link
                to="/get-a-quote"
                className="block md:inline-block my-2 md:my-0 md:mr-3 md:mx-0 mx-3 p-3 no-underline rounded hover:bg-gray-200"
                activeClassName="font-bold bg-gray-200"
              >
                Get A Quote
              </Link>
              */}

              {/* ------> BEGIN HIDE CONTENTS NOT READY
              

              <div className="group block md:inline-block">
                <Link
                  to="/about"
                  className="relative block md:inline-block my-2 md:my-0 md:mr-3 md:mx-0 mx-3 p-3 no-underline rounded hover:bg-gray-200"
                  activeClassName="font-bold bg-gray-200"
                  partiallyActive={true}
                >
                  About
                </Link>
                UNREADY DROPDOWN LINKS -----
                <div className="md:hidden group-hover:block md:absolute max-w-sm md:rounded overflow-hidden md:shadow-lg p-4 md:bg-bg bg-gray-100">
                  <Link
                    to="/about/#our-story"
                    className="block p-3 hover:bg-gray-200 rounded md:ml-0 ml-4"
                  >
                    Our Story
                  </Link>
                  <Link
                    to="/about/#how-we-work"
                    className="block p-3 hover:bg-gray-200 rounded md:ml-0 ml-4"
                  >
                    How We Work
                  </Link>
                  <Link
                    to="/about/#our-technology"
                    className="block p-3 hover:bg-gray-200 rounded md:ml-0 ml-4"
                  >
                    Our Technology
                  </Link>
                </div>
                UNREADY DROPDOWN LINKS -----
              </div>
              ------> END HIDE CONTENTS NOT READY
              */}

              {/*
              <Link
                to="/blog"
                className="block md:inline-block my-2 md:my-0 md:mr-3 md:mx-0 mx-3 p-3 no-underline rounded hover:bg-gray-200"
                activeClassName="font-bold bg-gray-200"
              >
                Blog
              </Link>
              */}

              <Link
                to="/contact"
                className="group block md:inline-block mt-2 md:mt-0 md:mx-0 mx-3 p-3 no-underline rounded bg-primary text-white"
                activeClassName="font-bold bg-gray-200"
              >
                <p>
                  Contact Us
                  <svg
                    className="mr-2 ml-2 group-hover:ml-4 group-hover:mr-0 fill-current inline-block h-3 align-middle"
                    style={{
                      transition: "margin .3s ease",
                      WebkitTransition: "margin .3s ease"
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    height="12"
                    width="12"
                  >
                    <polygon points="16.172 9 10.101 2.929 11.515 1.515 20 10 19.293 10.707 11.515 18.485 10.101 17.071 16.172 11 0 11 0 9" />
                  </svg>
                </p>
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
