import React from "react";

import MailingList from "./mailing-list";

function Footer() {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `);

  return (
    <footer className="bg-primary">
      <nav className="flex justify-between max-w-4xl mx-auto p-4 md:p-8 text-sm md:flex-row flex-col">
        <section className="md:w-1/2 text-white p-4">
          <MailingList />
        </section>

        <section className="md:w-1/2 text-white flex flex-col items-center p-4">
          <img
            alt="Knowledge, action, investment, success!"
            title="Knowledge, action, investment, success!"
            src={require(`../images/powerful.svg`)}
          />
          <p className="text-white mt-4">
            &copy; 2020 Gortek, LLC. All rights reserved.
            {/*
              <Breakpoint color="text-blue-600" />
              */}
          </p>
        </section>
      </nav>
    </footer>
  );
}

export default Footer;
