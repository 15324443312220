import React, { useRef, useState, useLayoutEffect } from 'react'

function useDimensions(trigger = null) {
  const ref = useRef()
  const [dimensions, setDimensions] = useState({})

  useLayoutEffect(() => {
    setDimensions(ref.current.getBoundingClientRect().toJSON())
  }, [trigger])
  
  return [ref, dimensions]
}

export default useDimensions
