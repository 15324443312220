import React from "react";
import { Location } from "@reach/router";

import Layout from "../components/layout";
import SEO from "../components/seo";
import MarTech from "../components/MarTech";
import twitterIcon from "../images/twitter.svg";
import facebookIcon from "../images/facebook.svg";
import linkedInIcon from "../images/linkedin.svg";

function ContactPage() {
  return (
    <Layout>
      <Location>
        {({ location }) => (
          <SEO
            title="Contact"
            pathname={location.pathname}
            keywords={[`gortek`, `app`, `startup`, `business`]}
          />
        )}
      </Location>

      <MarTech />

      <section>
        <h3 className="text-primary text-center md:mt-8">
          Questions or comments?
        </h3>
        <h6 className="text-center mt-8 md:mb-8">
          Drop us a line <span className="text-primary">hello@gortek.com</span>
        </h6>
      </section>

      <section className="flex flex-col items-center mt-8">
        <p className="">Follow us on social media</p>
        <div>
          <a
            className="inline-block mr-3"
            href="https://twitter.com/GortekLLC"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="h-12 p-2"
              alt="https://twitter.com/GortekLLC"
              title="@GortekLLC"
              src={twitterIcon}
              style={{ backgroundColor: "#29ace0" }}
            />
          </a>
          <a
            className="inline-block"
            href="https://www.facebook.com/GortekLLC/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="h-12 p-2 mr-3"
              alt="https://www.facebook.com/GortekLLC/"
              title="@GortekLLC"
              src={facebookIcon}
              style={{ backgroundColor: "#3569b4" }}
            />
          </a>
          <a
            className="inline-block"
            href="https://www.linkedin.com/in/gortek"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="h-12 p-2 mr-3"
              alt="https://www.linkedin.com/in/gortek"
              title="@gortek"
              src={linkedInIcon}
              style={{ backgroundColor: "#0177b5" }}
            />
          </a>
        </div>
      </section>

      <section className="hidden">
        <form className="mx-auto md:w-1/2">
          <label
            className="block font-bold mb-2 text-xs uppercase"
            htmlFor="first-name"
          >
            First Name
          </label>

          <input
            className="appearance-none block bg-gray-200 mb-6 px-3 py-2 rounded-md text-gray-700 w-full"
            id="first-name"
            type="text"
            placeholder="Bill"
          />

          <label
            className="block font-bold mb-2 text-xs uppercase"
            htmlFor="last-name"
          >
            Last Name
          </label>

          <input
            className="appearance-none block bg-gray-200 mb-6 px-3 py-2 rounded-md text-gray-700 w-full"
            id="last-name"
            type="text"
            placeholder="Murray"
          />

          <label
            className="block font-bold mb-2 text-xs uppercase"
            htmlFor="message"
          >
            Message
          </label>

          <textarea
            className="appearance-none bg-gray-200 mb-6 px-3 py-2 rounded-md text-gray-700 w-full"
            id="message"
            placeholder="Say something..."
            rows="8"
          />

          <button className="border-b-4 border-gray-800 hover:border-gray-700 bg-gray-700 hover:bg-gray-600 font-bold px-4 py-2 rounded text-sm text-white">
            Submit
          </button>
        </form>
      </section>
    </Layout>
  );
}

export default ContactPage;
